"use client";
import {
  default as PolicyCompanyModal,
  default as PolicyPersonalModal,
} from "@/app/_components/policy/PolicyCompany";
import { LanguageSwitch } from "@/app/_components/share/LanguageSwitch";
import TermsOUseCompanylModal from "@/app/_components/termsofuse/TermsOUseCompanys";
import TermsOUsePersonalModal from "@/app/_components/termsofuse/TermsOUsePersonal";
import { SERVICE_CENTER_PATHS } from "@/services/constants/routerPath";
import { Link } from "@chakra-ui/next-js";
import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();
  const [isOpenTermsCompanyModal, setIsOpenTermsCompanyModal] =
    React.useState(false);
  const [isOpenTermsPersonalModal, setIsOpenTermsPersonalModal] =
    React.useState(false);

  const [isOpenPolicyCompanyModal, setIsOpenPolicyCompanyModal] =
    React.useState(false);
  const [isOpenPolicyPersonalModal, setIsOpenPolicyPersonalModal] =
    React.useState(false);

  const onClose = () => {
    setIsOpenTermsCompanyModal(false);
    setIsOpenTermsPersonalModal(false);
    setIsOpenPolicyPersonalModal(false);
    setIsOpenPolicyCompanyModal(false);
  };

  return (
    <Stack as="footer" gap={0} color="menu">
      <Container maxW="container.xl">
        <HStack py={3} spacing={5} fontSize="sm" alignItems="baseline">
          <Box display="flex" flexDirection="column">
            <Link href="/about">{t("About Us")}</Link>
          </Box>
          <Box display="flex" flexDirection="column">
            <Link href={SERVICE_CENTER_PATHS.QNA}>{t("Customer Service")}</Link>
          </Box>
          <Box display="flex" flexDirection="column">
            <Link href="#">{t("Terms of Use")}</Link>
            <Text
              cursor="pointer"
              onClick={() => setIsOpenTermsPersonalModal(true)}
            >
              {t("Personal")}
            </Text>
            <Text
              cursor="pointer"
              onClick={() => setIsOpenTermsCompanyModal(true)}
            >
              {t("Corporate")}
            </Text>
          </Box>
          <Box display="flex" flexDirection="column">
            <Link href="#">{t("Privacy policy")}</Link>
            <Text
              cursor="pointer"
              onClick={() => setIsOpenPolicyPersonalModal(true)}
            >
              {t("Personal")}
            </Text>
            <Text
              cursor="pointer"
              onClick={() => setIsOpenPolicyCompanyModal(true)}
            >
              {t("Corporate")}
            </Text>
          </Box>
        </HStack>
      </Container>
      <Divider />
      <Container maxW="container.xl">
        <Box pt={4} pb={6}>
          <Flex
            direction={{ base: "column", lg: "row" }}
            align={{ base: "center", lg: "start" }}
            justifyContent="space-between"
            spacing={10}
          >
            <Link href="/">
              <Image
                src="/beauti_bucks_logo.jpeg"
                h="60px"
                alt="Beauti buck logo"
              />
            </Link>

            <Stack
              justifyContent="space-between"
              align={{ base: "center", lg: "start" }}
            >
              <LanguageSwitch />
              <Text textAlign={{ base: "center", lg: "left" }}>
                (주)에프엘씨굿컴퍼니
                <br />
                대표자 : 이성근
                <br />
                주소 : 서울특별시 금천구 범안로 1155, 5층(독산동, 동남빌딩)
                <br />
                사업자번호 : 242-86-02488
                <br />
                통신판매업 번호 : 제 2023-서울금천-2053호
                <br />
                개인정보처리책임자 : 이성근 (flcgood.official@gmail.com)
                <br />
                문의전화 : 02-2029-9255
                <br />
                저작권 © FLCGOOD Corp. 모든 권리 보유.
              </Text>
            </Stack>
          </Flex>
        </Box>
        <TermsOUseCompanylModal
          isOpen={isOpenTermsCompanyModal}
          onClose={onClose}
        />
        <TermsOUsePersonalModal
          isOpen={isOpenTermsPersonalModal}
          onClose={onClose}
        />
        <PolicyPersonalModal
          onClose={onClose}
          isOpen={isOpenPolicyPersonalModal}
        />
        <PolicyCompanyModal
          onClose={onClose}
          isOpen={isOpenPolicyCompanyModal}
        />
      </Container>
    </Stack>
  );
}
