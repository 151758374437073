"use client";

import { StorageKey } from "@/services/constants/global";
import { Link } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "usehooks-ts";

export const LanguageSwitch = () => {
  const { t, i18n } = useTranslation();

  const [savedLanguage, setSavedLanguage] = useLocalStorage(
    StorageKey.language,
    i18n.language
  );

  const nextLanguage = useMemo(
    () => (i18n.language === "ko" ? "en" : "ko"),
    [i18n.language]
  );

  const switchLang = (lang) => {
    const _next = lang ?? nextLanguage;
    i18n.changeLanguage(_next);
    setSavedLanguage(_next);
    localStorage.setItem(StorageKey.language, _next);
  };

  useEffect(() => {
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [savedLanguage, i18n]);

  return (
    <Link href="#" onClick={() => switchLang()} fontSize={14}>
      {t("Switch to {{lang}}", { lang: nextLanguage.toUpperCase() })}
    </Link>
  );
};
